import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/layout';
import SEO from '../components/seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => {
  return <Layout>
            <SEO title='Skills' />
            {children}
        </Layout>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Skills`}</h1>
    <h2>{`Languages`}</h2>
    <ol>
      <li parentName="ol">{`TypeScript / JavaScript / Node`}</li>
      <li parentName="ol">{`Python`}</li>
      <li parentName="ol">{`Rust`}</li>
      <li parentName="ol">{`HTML`}</li>
      <li parentName="ol">{`CSS / Less`}</li>
    </ol>
    <h2>{`Frameworks/Libraries`}</h2>
    <ol>
      <li parentName="ol">{`React`}</li>
      <li parentName="ol">{`Angular`}</li>
      <li parentName="ol">{`Jest`}</li>
      <li parentName="ol">{`Gatsby`}</li>
      <li parentName="ol">{`NextJS`}</li>
      <li parentName="ol">{`Ionic`}</li>
      <li parentName="ol">{`Backbone`}</li>
    </ol>
    <h2>{`Tools`}</h2>
    <ol>
      <li parentName="ol">{`Webpack`}</li>
      <li parentName="ol">{`Grunt`}</li>
      <li parentName="ol">{`Git`}</li>
      <li parentName="ol">{`GitHub`}</li>
      <li parentName="ol">{`Team Foundation Server (TFS)`}</li>
      <li parentName="ol">{`Docker`}</li>
    </ol>
    <h2>{`DevOps`}</h2>
    <ol>
      <li parentName="ol">{`Microsoft Azure`}</li>
      <li parentName="ol">{`Amazon Web Services (AWS) - VPC, EC2, S3, Route53`}</li>
      <li parentName="ol">{`Netlify`}</li>
      <li parentName="ol">{`Heroku`}</li>
      <li parentName="ol">{`Now (by Zeit)`}</li>
    </ol>
    <h2>{`Process`}</h2>
    <ol>
      <li parentName="ol">{`Agile`}</li>
      <li parentName="ol">{`Scrum`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      